<template>
  <div v-if="permission.access">
    <div class="w-100 d-flex align-items-center justify-content-between mb-1">
      <div>
        <label>Show</label>
        <v-select
          @on-change="paginateChange"
          :options="perPageOptions"
          v-model="search.paginate"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </div>
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="mx-2 mt-2 mb-0">
            <b-row class="">
              <b-col cols="12" md="4">
                <div class="form-group">
                  <Select
                    v-model="search.type"
                    @on-change="SearchData"
                    filterable
                    :clearable="true"
                    placeholder="Select Task Type"
                  >
                    <Option :value="1">Check List</Option>
                    <Option :value="2">Time Tracker</Option>
                    <Option :value="3">Progressbar</Option>
                    <Option :value="4">Feedback</Option>
                    <Option :value="5">Hour</Option>
                    <Option :value="6">Quantity</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="form-group">
                  <Select
                    v-model="search.work_type"
                    @on-change="SearchData"
                    filterable
                    :clearable="true"
                    placeholder="Select Work Type"
                  >
                    <Option :value="1">Daily Work</Option>
                    <Option :value="2">Weekly Work</Option>
                    <Option :value="4">Quarterly Work</Option>
                    <Option :value="3">Monthly Work</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="form-group">
                  <Select
                    v-model="search.status"
                    @on-change="SearchData"
                    filterable
                    :clearable="true"
                    placeholder="Select Status"
                  >
                    <Option :value="1">Active</Option>
                    <Option :value="2">Inactive</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="form-group">
                  <Select
                    v-model="search.department_id"
                    @on-change="SearchData"
                    filterable
                    multiple
                    :clearable="true"
                    placeholder="Select Department"
                  >
                    <Option
                      :value="department.id"
                      v-for="(department, index) in departments"
                      :key="index"
                      v-if="departments.length"
                      >{{ department.name }}</Option
                    >
                  </Select>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="form-group">
                  <Input
                    type="text"
                    v-model="search.search_data"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th>Task</th>
                  <th class="text-center">Work Status</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                  <th class="align-middle" v-if="permission.delete">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(task, index) in tasks.data"
                  :key="index"
                  v-if="tasks.data.length"
                >
                  <td class="align-middle text-center">
                    {{ tasks.from + index }}
                  </td>
                  <td class="align-middle">
                    <b>{{ task.name }} </b>
                  </td>
                  <td class="align-middle text-center">
                    <span class="badge badge-success" style="margin-right: 5px">
                      {{
                        task.type == 1
                          ? "Check List"
                          : task.type == 2
                          ? "Time Tracker"
                          : task.type == 3
                          ? "Progressbar"
                          : task.type == 4
                          ? "Feedback"
                          : task.type == 5
                          ? "Hour"
                          : "Quantity"
                      }}
                    </span>
                    <span class="badge badge-primary" v-if="task.work_type">
                      {{
                        task.work_type == 1
                          ? "Daily Work"
                          : task.work_type == 2
                          ? "Weekly Work"
                          : task.work_type == 3
                          ? "Monthly Work"
                          : "Quarterly Work"
                      }}
                    </span>
                  </td>
                  <td class="text-center align-middle">
                    <span
                      class="badge"
                      :class="
                        task.status == true ? 'badge-success' : 'badge-danger'
                      "
                    >
                      {{ task.status == true ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <div class="btn-group">
                      <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="edit(task.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button>
                    </div>
                  </td>
                  <td v-if="permission.delete" class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="task.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ tasks.from ? tasks.from : 0 }} to
                  {{ tasks.to ? tasks.to : 0 }} of
                  {{ tasks.total ? tasks.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="tasks"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="(form.id == '' ? 'Create' : 'Update') + ' Default Task'"
        >
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row mb-2">
                  <div class="col-md-12">
                    <b-form-group label="Select Task Type" label-for="type">
                      <validation-provider
                        #default="{ errors }"
                        name="Task Type"
                        rules="required"
                      >
                        <Select
                          v-model="form.type"
                          filterable
                          :clearable="true"
                          name="type"
                          id="type"
                          placeholder="Select Task Type"
                        >
                          <Option :value="1">Check List</Option>
                          <Option :value="2">Time Tracker</Option>
                          <Option :value="3">Progressbar</Option>
                          <Option :value="4">Feedback</Option>
                          <Option :value="5">Hour</Option>
                          <Option :value="6">Quantity</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Work Type"
                      label-for="work_type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Work Type"
                      >
                        <Select
                          v-model="form.work_type"
                          filterable
                          :clearable="true"
                          name="work_type"
                          id="work_type"
                          placeholder="Select Work Type"
                        >
                          <Option :value="1">Daily Work</Option>
                          <Option :value="2">Weekly Work</Option>
                          <Option :value="4">Quarterly Work</Option>
                          <Option :value="3">Monthly Work</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Task Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="Task Name"
                        rules="required"
                      >
                        <Input
                          name="name"
                          type="text"
                          v-model="form.name"
                          id="name"
                          placeholder="Enter Task Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Department"
                      label-for="department_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Department"
                      >
                        <Select
                          multiple
                          v-model="form.department_id"
                          filterable
                          :clearable="true"
                          name="department_id"
                          id="department_id"
                          placeholder="Select Department"
                        >
                          <Option
                            :value="department.id"
                            v-for="(department, index) in departments"
                            :key="index"
                            v-if="departments.length"
                            >{{ department.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        type: null,
        work_type: null,
        status: null,
        department_id: [],
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        type: null,
        work_type: null,
        department_id: [],
        name: null,
        status: true,
      },
      tasks: {},

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getDepartment");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const tasks = await this.callApi(
        "post",
        "/app/project/default/tasks?page=" + this.search.page,
        this.search
      );
      if (tasks.status == 200) {
        this.tasks = tasks.data.tasks;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/app/project/default/tasks/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.type = null;
      this.form.work_type = null;
      this.form.department_id = [];
      this.form.name = null;
      this.form.status = true;
    },

    async edit(id) {
      this.Close();
      const task = await this.callApi(
        "post",
        "/app/project/default/tasks/edit/" + id
      );
      if (task.status == 200) {
        this.form.id = task.data.task.id;
        this.form.type = task.data.task.type;
        this.form.name = task.data.task.name;
        this.form.work_type = task.data.task.work_type;
        task.data.task.departments.forEach((data) => {
          this.form.department_id.push(data.department_id);
        });
        this.form.status = task.data.task.status == true ? true : false;
      }
    },

    add() {
      axios
        .post("/app/project/default/tasks/store", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.Close();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.work_type)
              this.e(e.response.data.errors.work_type[0]);
            if (e.response.data.errors.department_id)
              this.e(e.response.data.errors.department_id[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.tasks.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.tasks.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartment;
    },
    permission() {
      return this.$store.getters.getPermissions.default_task;
    },
  },
};
</script>

<style></style>
